<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title>
                <span>Exportar Ordenes</span>
            </v-card-title>
            <v-card-text>
               
                <div class="row" >
                    <div class="col-md-6" >
                         <v-alert border="top" colored-border type="info" elevation="1" >
                            <v-alert class="mt-4" color="white" >
                                Se exportar&aacute;n <b>los primeros 1000 registros</b> a partir de la fecha seleccionada.
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="true" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field 
                                            v-model="dateText" label="Fecha desde" prepend-icon="event" readonly v-bind="attrs" v-on="on" :rules="[v => !!v || 'Debes completar este campo']" 
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" scrollable @input="formatearRango"></v-date-picker>
                                </v-menu>
                                <v-btn dark color="success" :disabled="!readyFor || loading" @click="exportExcelByConf" >Exportar</v-btn>
                            </v-alert>
                        </v-alert>
                        
                    </div>
                    <div class="col-md-6" >
                        <v-alert border="top" colored-border type="warning" elevation="1" >
                            <v-alert class="mt-4" color="white" >Se enviar&aacute; por mail in link para acceder al archivo con <b>todos los registros</b>.</v-alert>
                             <v-btn dark color="success" @click="exportExcelAllByConf" >Exportar</v-btn>
                        </v-alert>
                       
                         
                        
                    </div>
                </div>
      
                
                <div class="row" >
                   
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="blue darken-1" @click="showDialog = false">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            date: "",
            loading: false,
            dateText: '',
            menu: false,
        }
    },
    created(){
        this.$parent.$on('open-exportar-ordenes-storegroup-dialog', () => {
            this.showDialog = true;
        });
    },
    methods:{
        exportExcelByConf(){
            var vm = this;
            this.axios({
                url: 'orders/export_with_configuration/excel?store_group=1&date='+this.dateText,
                method: 'GET',
                responseType: "blob",
            }).then( response => {
                const newBlob = new Blob([response.data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
                this.showDialog = false;
            }).catch( error => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar, verifique la fecha ingresada.");
            });
        },
        exportExcelAllByConf(){
            var vm = this;
            this.axios({
                url: 'orders/export_all_with_configuration/excel?store_group=1', method: 'GET',
            }).then( response => {
                vm.abrirMensajeSuccess("En minutos recibirá por mail el link para acceder al recurso.");
            }).catch( error => {
                console.log(error);
            });
        },
        formatearRango()
        {
            if(!this.date.length){
                this.dateText = '';
            }else{
                this.dateText = this.date;
            }
        },
        abrirMensajeSuccess(mensaje) {
            this.$bvToast.toast(mensaje, { title: `Información`, variant: "success", solid: true, toaster: "b-toaster-bottom-center" });
            this.fetchData();
        },
        abrirMensajeError(mensaje) {
            this.$bvToast.toast(mensaje, {
                title: `Error`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-center",
            });
        }
    },
    computed:{
        readyFor(){
            return this.dateText != "" ? true : false;
        }
    }
}
</script>